import axios, { type AxiosError, type AxiosInstance, type AxiosResponse, type RawAxiosRequestHeaders } from "axios"

import Urls from "main-app/api/urls"
import type { IJwtTokenRefreshModel } from "main-app/models/jwt"
import { ELocalStorageServiceItemKey, EStorageServiceType, StorageService } from "main-app/services"

import { handle401Error as prevent401Error } from "../main-app/utils/handle-401-error"

import { isNullOrUndefined } from "./utils/gates"
import { isAccessTokenValid, isRefreshTokenValid } from "./utils/tokens"

const storageService: StorageService = new StorageService(EStorageServiceType.Local)

const headers: RawAxiosRequestHeaders = {
    "X-Requested-With": "XMLHttpRequest"
}
const xsrfCookieName: string = "csrftoken"
const xsrfHeaderName: string = "X-CSRFToken"

const http: AxiosInstance = axios.create({
    xsrfCookieName,
    xsrfHeaderName,
    withXSRFToken: true,
    headers
})

async function refreshAccessToken(): Promise<void> {
    try {
        const response = await axios.post(
            Urls.postTokenRefresh(),
            {
                refresh: storageService.getItem<string>(ELocalStorageServiceItemKey.TokenRefresh)
            } as IJwtTokenRefreshModel,
            {
                headers,
                xsrfHeaderName,
                xsrfCookieName
            }
        )
        storageService.setItem<string>(ELocalStorageServiceItemKey.TokenAccess, response.data.access)
    } catch (error) {
        console.log(error)
    }
}

http.interceptors.response.use(
    (response: AxiosResponse) => {
        if (response.status >= 200 && response.status < 303 && response.headers?.location) {
            // eslint-disable-next-line no-restricted-globals
            location.href = response.headers.location
        }

        return response
    },
    (error: AxiosError) => {
        return Promise.reject(error)
    }
)

http.interceptors.request.use(
    async request => {
        if (request.data && request.data.type && request.data.type === "html") {
            let form = ""

            request.data.body?.forEach(
                (item): void => ((form += `${item.name}=${encodeURIComponent(item.value)}&`), void 0)
            )

            request.data = form
        }

        let accessToken: string = storageService.getItem<string>(ELocalStorageServiceItemKey.TokenAccess)

        if (!isNullOrUndefined(accessToken)) {
            if (!isAccessTokenValid()) {
                await refreshAccessToken()
                accessToken = storageService.getItem<string>(ELocalStorageServiceItemKey.TokenAccess)
            }

            request.headers.Authorization = `Bearer ${accessToken}`
        }

        if (!isNullOrUndefined(storageService.getItem<string>(ELocalStorageServiceItemKey.TokenRefresh))) {
            if (!isRefreshTokenValid()) {
                prevent401Error()
            }
        }

        return request
    },
    (error: AxiosError) => Promise.reject(error)
)

export { http }
