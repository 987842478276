import { jwtDecode } from "jwt-decode"
import { DateTime } from "luxon"

import { ELocalStorageServiceItemKey, StorageService } from "main-app/services"

const storageService: StorageService = new StorageService()

function isTokenValid(token: string): boolean {
    try {
        return DateTime.now().toSeconds() <= DateTime.fromSeconds(jwtDecode(token).exp).toSeconds()
    } catch (error) {
        return true
    }
}

function isAccessTokenValid(): boolean {
    return isTokenValid(storageService.getItem<string>(ELocalStorageServiceItemKey.TokenAccess))
}

function isRefreshTokenValid(): boolean {
    return isTokenValid(storageService.getItem<string>(ELocalStorageServiceItemKey.TokenRefresh))
}

export { isAccessTokenValid, isRefreshTokenValid }
