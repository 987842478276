import { storageUtils } from "./utils"

enum ESessionStorageServiceItemKey {
    UserLogout = "userLoggedOut"
}

class SessionStorageService extends Storage {
    static setItem<T>(key: ESessionStorageServiceItemKey, value: T): void {
        sessionStorage.setItem(key, storageUtils.setStorageItemValue<T>(value))
    }

    static getItem(key: ESessionStorageServiceItemKey): string | null {
        return storageUtils.getStorageItemValue(sessionStorage.getItem(key))
    }

    static removeItem(key: ESessionStorageServiceItemKey): void {
        sessionStorage.removeItem(key)
    }

    static clear(): void {
        sessionStorage.clear()
    }
}

export { SessionStorageService, ESessionStorageServiceItemKey }
