import { isNullOrUndefined, isString } from "common/utils/gates"

function getStorageItemValue(value: string | null): string | null {
    if (isString(value)) return value

    if (isNullOrUndefined(value)) return null

    return value
}

function setStorageItemValue<T>(value: T): string {
    return isString(value) ? value : JSON.stringify(value)
}

const storageUtils = { getStorageItemValue, setStorageItemValue }

export { storageUtils }
