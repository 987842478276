import {
    ELocalStorageServiceItemKey,
    ESessionStorageServiceItemKey,
    LocalStorageService,
    SessionStorageService
} from "."

type EStorageServiceItemKey = ELocalStorageServiceItemKey | ESessionStorageServiceItemKey

type TStorage = Omit<Storage, "key">

enum EStorageServiceType {
    Local,
    Session
}

const storageMap: { [K in EStorageServiceType]: TStorage } = {
    [EStorageServiceType.Local]: LocalStorageService,
    [EStorageServiceType.Session]: SessionStorageService
}

class StorageService {
    private storage: TStorage

    constructor(storageType: EStorageServiceType = EStorageServiceType.Local) {
        this.storage = storageMap[storageType]
    }

    public setItem<T>(key: EStorageServiceItemKey, value: T): void {
        this.storage.setItem(key, value)
    }

    public getItem<T>(key: EStorageServiceItemKey): T {
        return this.storage.getItem(key)
    }

    public removeItem(key: EStorageServiceItemKey): void {
        this.storage.removeItem(key)
    }

    public clearStorage(): void {
        this.storage.clear()
    }
}

export { StorageService, EStorageServiceType }
